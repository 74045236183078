// 部署详情
<template>

    <div class='taskDetails baseBg'>
        <backUp></backUp>
        <el-row :gutter="15">
            <el-col :span="6">
                <div class="infoBox">
                    <div class="cardBox">
                        <div class="cardTitle"><img src="../../assets/imgs/icon_renwu.png"
                                 alt="">实施信息</div>
                        <p>
                            <span class="label">计划名称：</span><span class="value">{{detailsInfo.name || '无'}}</span>
                        </p>
                        <p>
                            <span class="label">实施负责人：</span><span class="value">{{detailsInfo.operatorName || '无'}}</span>
                        </p>
                        <p>
                            <span class="label">计划工作时间：</span><span class="value">{{detailsInfo.startTime || '无'}}{{' ~ ' +detailsInfo.endTime || '无'}}</span>
                        </p>
                        <!-- <p>
                            <span class="label">设备数量:</span><span class="value">{{detailsInfo.totalNumber}}</span>
                        </p>
                        <p>
                            <span class="label">联系人：</span><span class="value">{{detailsInfo.taskLinkName}}</span>
                        </p>
                        <p>
                            <span class="label">联系电话：</span><span class="value">{{detailsInfo.taskLinkPhone}}</span>
                        </p> -->
                        <p>
                            <span class="label">任务描述：</span><span class="value"  v-html="detailsInfo.description ? detailsInfo.description.replace(/\n/g, '<br>') : '无'">
                                </span>
                        </p>
                    </div>
                    <div class="cardBox"
                         v-if="detailsInfo.locationInfoVo">
                        <div class="cardTitle"><img src="../../assets/imgs/icon_didian.png"
                                 alt="">客户信息</div>
                        <p>
                            <span class="label">客户：</span><span class="value">{{detailsInfo.locationInfoVo.customerCompanyName || '无'}}</span>
                        </p>
                        <p>
                            <span class="label">地址：</span><span class="value">{{detailsInfo.locationInfoVo.location || '无'}}</span>
                        </p>
                        <!-- <p>
                            <span class="label">负责人：</span><span class="value">{{detailsInfo.locationInfoVo.linkName}}</span>
                        </p>
                        <p>
                            <span class="label">联系电话：</span><span class="value">{{detailsInfo.locationInfoVo.linkPhone}}</span>
                        </p> -->
                    </div>
                    <div class="cardBox"
                         v-if="detailsInfo.systemInfoVo">
                        <div class="cardTitle"><img src="../../assets/imgs/icon_xitong.png"
                                 alt="">系统信息</div>
                        <p>
                            <span class="value">{{detailsInfo.systemInfoVo.systemName || '无'}}</span>
                        </p>
                        <p>
                            <span class="value">{{detailsInfo.systemInfoVo.versionName || '无'}}</span>
                        </p>
                    </div>
                    <div class="cardBox"
                         v-if="detailsInfo.errorFinishFlag">
                        <div class="cardTitle"><img src="../../assets/imgs/icon_xitong.png"
                                 alt="">完成情况</div>
                        <p>
                            <span class="label">异常描述：</span><span class="value">{{detailsInfo.errorDescription || '无'}}</span>
                        </p>
                    </div>
                </div>
            </el-col>
            <el-col :span="18">
                <el-tabs v-model="activeName">
                    <el-tab-pane label="出库验收信息"
                                 name="出库验收信息">
                        <com-out-info ref="popOutinDeviceList"
                                      v-if="activeName === '出库验收信息'"
                                      :detailsInfo="detailsInfo"></com-out-info>
                    </el-tab-pane>
                    <el-tab-pane label="安装信息"
                                 name="安装信息">
                        <com-install-info v-if="activeName === '安装信息'"
                                          :detailsInfo="detailsInfo"></com-install-info>
                    </el-tab-pane>
                </el-tabs>
            </el-col>
        </el-row>

    </div>

</template>

<script>
import ComInstallInfo from "./components/comInstallInfo";
import ComOutInfo from "./components/comOutInfo";
import { Loading } from "element-ui";
import API from "@/api/safeguardManage.js";
import backUp from "@/components/backUp";
export default {
    name: "taskDetails",

    props: [],

    components: {
        ComInstallInfo,
        ComOutInfo,
        backUp,
    },

    data() {
        return {
            activeName: "出库验收信息",
            detailsInfo: "", // 详情
        };
    },

    created() {},

    mounted() {},

    methods: {
        // 获取任务详情
        detailsTask() {
            let loading = Loading.service({
                target: document.querySelector(".taskDetails"),
            });
            API.detailsTask({ id: this.$route.query.id }).then((res) => {
                loading.close();
                this.detailsInfo = res.content;
                if (this.$refs.popOutinDeviceList) {
                    this.$refs.popOutinDeviceList.tableData.data =
                        res.content.outFlowList;
                }
            });
        },
    },

    computed: {},

    watch: {
        // 获取任务详情
        activeName: {
            handler: function () {
                this.$nextTick(() => {
                    this.detailsTask();
                });
            },
            immediate: true,
        },
    },
};
</script>

<style lang='scss' scoped>
.tipBox {
    float: left;
}
.tipBox > div {
    width: 54px;
    height: 54px;
    float: left;
    margin-right: 10px;
    background: #ffffff url("../../assets/imgs/icon_chuku.png") no-repeat center
        center;
    background-size: 28px auto;
    border: 1px solid #0092ff;
}
.tipBox > div.yanshou {
    background-image: url("../../assets/imgs/icon_yanshou.png");
}
.inoutList {
    border: 1px solid #eee;
}
</style>
