// 安装信息
<template>

    <div class="comInstallInfo">
        <div class="tableBox">
            <el-table :data="installInfoList"
                      border
                      stripe>
                <el-table-column v-for="col in tableData.columns"
                                 :prop="col.id"
                                 :key="col.id"
                                 :label="col.label"
                                 align="center"
                                 :min-width="col.width">
                </el-table-column>
                <el-table-column prop="id"
                                 label="操作"
                                 align="center"
                                 min-width="100">
                    <template slot-scope="scope">
                        <el-link type="primary"
                                 v-if="scope.row.installStatus !== '未安装'"
                                 @click="openpopInstallInfo(scope.row)">查看</el-link>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 安装信息 -->
        <pop-install-info v-if="popInstallInfoVisible"
                          :installInfoDetails="installInfoDetails"
                          @close="closepopInstallInfo"></pop-install-info>
    </div>

</template>

<script>
import PopInstallInfo from "./popInstallInfo";
export default {
    name: "comInstallInfo",

    props: {
        // 详情
        detailsInfo: {
            type: Object,
            default() {
                return {
                    checkedList: [],
                };
            },
        },
    },

    components: {
        PopInstallInfo,
    },

    data() {
        return {
            tableData: {
                columns: [
                    { id: "qrCode", label: "设备编号", width: "100" },
                    { id: "empType", label: "设备类型", width: "100" },
                    { id: "provider", label: "供应商", width: "100" },
                    { id: "typeModel", label: "规格型号", width: "100" },
                    { id: "installStatus", label: "设备状态", width: "100" },
                ],
            },
            popInstallInfoVisible: false, // 安装信息弹窗显示
            installInfoDetails: null, // 安装信息详情
        };
    },

    created() {},

    mounted() {},

    methods: {
        // 安装信息 打开弹框
        openpopInstallInfo(row) {
            this.installInfoDetails = row;
            this.popInstallInfoVisible = true;
        },
        // 安装信息 关闭弹框
        closepopInstallInfo() {
            this.installInfoDetails = null;
            this.popInstallInfoVisible = false;
        },
    },

    computed: {
        // 列表
        installInfoList() {
            let data = this.detailsInfo.checkedList;
            console.log(data);
            data.forEach((item) => {
                item.installStatus = item.installDate ? "已安装" : "未安装";
            });
            return data;
        },
    },

    watch: {},
};
</script>

<style lang="scss" scoped>
// .comInstallInfo{

// }
</style>
