// 出库验收信息
<template>

    <div class="comOutInfo">

        <div class="deviceInfoListBox cardBox"
             style="margin-top: 0;">
            <div class="cardTitle">设备信息</div>
            <ul class="deviceInfoList">
                <li v-for="(item,idx) in detailsInfo.deviceInfoVos"
                    :key="idx">
                    <div class="borderBox">
                        <p class="name">
                            <span v-if="item.empType">{{item.empType}}</span>
                            <span v-if="item.provider">/{{item.provider}}</span>
                            <span v-if="item.empTypeModel">/{{item.empTypeModel}}</span>
                        </p>
                        <p class="numBox">
                            <span>{{item.number}}</span>
                            <span>{{item.outNumber}}</span>
                            <span>{{item.checkedNum}}</span>
                        </p>
                    </div>
                    <div class="tipBox">
                        <!-- 出库完成 icon -->
                        <div class="chuku"
                             v-if="item.number === item.outNumber"></div>
                        <!-- 验收完成 icon -->
                        <div class="yanshou"
                             v-if="item.number === item.outNumber && item.number === item.checkedNum"></div>
                    </div>
                </li>
            </ul>
        </div>

        <div class="listBox">
            <div class="inoutList tableBox">
                <el-table :data="tableData.data"
                          border
                          stripe>
                    <el-table-column prop="createTime"
                                     label="出库日期"
                                     min-width="100">
                    </el-table-column>
                    <el-table-column prop="number"
                                     label="出库数量"
                                     min-width="100">
                    </el-table-column>
                    <el-table-column prop="operatorName"
                                     label="出库人"
                                     min-width="100">
                    </el-table-column>
                    <el-table-column prop="checkTime"
                                     label="验收状态"
                                     min-width="100">
                        <template slot-scope='scope'>
                            {{scope.row.checkTime ? "已验收" : "未验收"}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="checkedNum"
                                     label="验收数量"
                                     min-width="100">
                    </el-table-column>
                    <el-table-column prop='id'
                                     label='操作'
                                     min-width='100'>
                        <template slot-scope='scope'>
                            <el-link type='primary'
                                     @click='details(scope.row)'>查看</el-link>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <!-- 弹框 -->
        <pop-outin-device-list v-if="outinDeviceListVisible"
                               :row="this.rowDetail"
                               @close="closeoutinDeviceList"></pop-outin-device-list>

    </div>

</template>

<script>
import PopOutinDeviceList from "./popOutinDeviceList";
export default {
    name: "comOutInfo",

    props: {
        // 详情
        detailsInfo: {
            type: Object,
            default() {
                return {
                    detailsInfo: [],
                };
            },
        },
    },

    components: {
        PopOutinDeviceList,
    },

    data() {
        return {
            tableData: {
                data: [],
            },

            outinDeviceListVisible: false, // 弹窗显示
            rowDetail: "", // 当前选择的行信息
        };
    },

    created() {},

    mounted() {},

    methods: {
        // 查看出库设备
        details(row) {
            this.rowDetail = row;
            this.outinDeviceListVisible = true;
        },
        // 关闭出库设备弹框
        closeoutinDeviceList() {
            this.outinDeviceListVisible = false;
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
.deviceInfoListBox {
    height: 300px;
    margin-top: 15px;
    margin-bottom: 15px;
    border: 1px solid #eee;
    padding: 15px;
    overflow-y: auto;
}
.deviceInfoList {
    overflow: hidden;
    padding-left: 0;
}
.deviceInfoList li {
    float: left;
    width: calc(33.33% - 15px);
    margin-bottom: 10px;
    overflow: hidden;
    padding: 5px 10px;
}
.deviceInfoList li:nth-child(1n) {
    margin-right: 15px;
}
.deviceInfoList li .name,
.deviceInfoList li .numBox {
    float: left;
    height: 100%;
}
.deviceInfoList li .name {
    width: 266px;
}
.deviceInfoList .borderBox {
    width: 351px;
    height: 54px;
    background: url("../../../assets/imgs/borderBox.png") no-repeat center
        center;
    background-size: 100% 100%;
    float: left;
    margin-right: 10px;
}
.deviceInfoList .borderBox p.name {
    padding-left: 19px;
    margin: 0;
    line-height: 54px;
}
.deviceInfoList .borderBox .numBox {
    padding: 0;
    line-height: 54px;
    margin: 0;
}
.deviceInfoList .borderBox .numBox span {
    margin-right: 20px;
}
.deviceInfoList .borderBox .numBox span:nth-child(1) {
    color: #0192ff;
}
.deviceInfoList .borderBox .numBox span:nth-child(2) {
    color: #ff8800;
}
.deviceInfoList .borderBox .numBox span:nth-child(3) {
    color: #07ccbf;
    margin-right: 0;
}
</style>
